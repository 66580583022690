<template>
    <div>
        <div class="uk-padding-small">
            <vk-breadcrumb class="uk-margin-remove">
                <vk-breadcrumb-item :disabled="true">Liste des utilisateurs sous-traitant</vk-breadcrumb-item>
            </vk-breadcrumb>
        </div>
        <div class="uk-padding-small">
            <vue-good-table
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                :isLoading.sync="isLoading"
                :pagination-options="pagination"
                :totalRows="remoteData.totalRecords"
                :rows="remoteData.rows"
                :columns="columns"
                :sort-options="{
                    enabled: false,
                }"
                styleClass="vgt-table striped bordered condensed"
            >
                <template slot="loadingContent">
                    <span class="vgt-loading__content">Téléchargement...</span>
                </template>
                <template v-slot:table-row="props">
                    <div v-if="props.column.field === 'accountType'">
                        <!-- {{ props.row.accountType && props.row.roles[0] === "admin" ? "Manager sous-traitant" : "Agent sous-traitant" }} -->
                        <div v-if="props.row.accountType == 'agencyAccount'">Operateur suez</div>
                        <div v-else-if="props.row.accountType == 'workerAccount'">
                            {{ props.row.roles[0] === 'admin' ? 'Manager sous-traitant' : 'Agent sous-traitant' }}
                        </div>
                        <div v-else-if="props.row.accountType == 'superManagerAccount'">Administrateur</div>
                    </div>
                    <div v-else-if="props.column.field === 'actions'" class="text-center">
                        <button type="button" class="btn btn-primary" @click="editUser(props.row.id)">
                            <font-awesome-icon icon="fa-regular fa-eye" />
                        </button>
                    </div>
                </template>
            </vue-good-table>
        </div>
    </div>
</template>

<script>
import store from '@/store'
import backendApi from '@/backend/api'

export default {
    name: 'UsersWorkers',
    data: () => ({
        timeout: null,
        isLoading: false,
        serverParams: {
            columnFilters: {},
            sort: [],
            page: 1,
            perPage: 10,
            searchTerm: '',
        },
        pagination: {
            enabled: true,
            mode: 'records',
            perPage: 10,
            position: 'both',
            perPageDropdown: [10, 25, 50],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            jumpFirstOrLast: true,
            firstLabel: 'Début',
            lastLabel: 'Fin',
            nextLabel: 'Suiv.',
            prevLabel: 'Préc.',
            rowsPerPageLabel: 'Lignes par page ',
            ofLabel: 'de',
            pageLabel: 'page',
            allLabel: 'Tous',
        },
        remoteData: {
            rows: [],
            totalRecords: 0,
        },
    }),
    methods: {
        editUser(userId) {
            if (store.getters['login/isAdminFunc']) {
                this.$router.push({ name: 'user_st_edit', params: { userId: userId } })
            } else {
                this.$router.push({ name: 'user_edit', params: { userId: userId } })
            }
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps)
        },

        onPageChange(params) {
            this.updateParams({ page: params.currentPage })
            this.loadItems()
        },

        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage })
            this.loadItems()
        },
        onSortChange(params) {
            this.updateParams({ sort: params })
            this.loadItems()
        },
        onColumnFilter(params) {
            this.updateParams(params)
            this.loadItems()
        },
        loadItems() {
            this.isLoading = true
            backendApi
                .getWorkerUsersList(store.state.login.user.token, this.serverParams)
                .then((data) => {
                    this.remoteData.totalRecords = data.totalRecords
                    this.remoteData.rows = data.rows
                    this.updateParams({
                        page: data.currentPage,
                        perPage: data.maxResult,
                    })
                })
                .catch((e) => {
                    this.isLoading = false
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
        },
    },
    computed: {
        columns: function () {
            return [
                {
                    label: 'Identifiant',
                    field: 'username',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: 'Nom',
                    field: 'lastName',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: 'Prénom',
                    field: 'firstName',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: 'Profil',
                    field: 'profile',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: 'Domaine',
                    field: 'domain',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: 'Statut',
                    field: 'status',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    width: '1%',
                },
            ]
        },
    },
    mounted() {
        this.loadItems()
    },
}
</script>

<style scoped></style>
